import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Home() {
  const carouselRef1 = useRef(null);

  const settings = {
    arrows: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const moveCarousel = (carouselRef, direction) => {
    if (direction === "left") {
      carouselRef.slickPrev();
    } else if (direction === "right") {
      carouselRef.slickNext();
    }
  };

  const handleLeftArrowClick1 = () => {
    moveCarousel(carouselRef1.current, "left");
  };

  const handleRightArrowClick1 = () => {
    moveCarousel(carouselRef1.current, "right");
  };

  return (
    <div>
      <div className="hidden h-screen bg-center bg-no-repeat bg-cover md:block" style={{ backgroundImage: `url(/images/desktop-index.png)` }}></div>
      
      <div className="block md:hidden">
        <Navbar />
        <main className="grid px-3 pt-16 pb-7">
          {/* Gif banner */}
          <div className="rounded-[21px] bg-white mt-5 shadow-[0_3px_16px_0px_rgba(0,0,0,0.25)]">
            <div>
              <img
                id="banner-animado"
                src="/images/animated-banner.gif"
                className="rounded-t-[21px]"
                alt="Banner animado"
                autoPlay
                loop
              />
            </div>
            <div className="mx-3 mt-4">
              <p className="text-[26px] font-bold leading-[33px] text-[#FF785F] mb-5">
                Innovación y liderazgo en el{" "}
                <span className="text-[#00005E]">
                  Mercado de Subastas Virtuales
                </span>
              </p>
              <p className="text-[21px] font-semibold leading-[29px] mb-3 text-[#00005E]">
                Somos Market-makers por naturaleza
              </p>
              <p className="text-[16px] leading-[21px] mb-9 text-[#5D6172]">
                Durante 15 años, nos hemos enfocado en{" "}
                <span className="font-semibold">
                  desarrollar mercados sostenibles
                </span>{" "}
                a través del uso de nuevas tecnologías y metodologías ágiles,{" "}
                <span className="font-semibold">
                  generando valor de manera transaccional
                </span>
                , tanto para nuestros clientes, como para nuestros usuarios.
              </p>
            </div>
          </div>

          {/* Info slider */}
          <div className="relative overflow-hidden rounded-[21px] bg-[#FBB44C] mt-5 shadow-[0_3px_16px_0px_rgba(0,0,0,0.25)]">
            <Slider {...settings} ref={carouselRef1}>
              <div className="flex flex-col items-center w-screen min-w-full py-5">
                <div className="text-center text-white">
                  <p className="text-[3rem] font-bold leading-[90px] mb-0 slider-title tracking-[-3px]">
                    30,480,090
                  </p>
                  <img
                    src="/images/info-slider-platform-visits.png"
                    alt="Número de visitas en plataforma"
                    style={{ maxWidth: "70%", height: "auto" }}
                    className="leading-[26px] mx-auto"
                  />
                </div>
              </div>

              <div className="flex flex-col items-center w-screen min-w-full py-5">
                <div className="text-center text-white">
                  <p className="text-[69px] font-bold leading-[90px] mb-0 slider-title tracking-[-3px]">
                    115,569
                  </p>
                  <img
                    src="/images/info-slider-users-registered.png"
                    alt="Cantidad de usuarios registrados"
                    style={{ maxWidth: "70%", height: "auto" }}
                    className="leading-[26px] mx-auto"
                  />
                </div>
              </div>

              <div className="flex flex-col items-center w-screen min-w-full py-5">
                <div className="text-center text-white">
                  <p className="text-[69px] font-bold leading-[90px] mb-0 slider-title tracking-[-3px]">
                    33,337
                  </p>
                  <img
                    src="/images/info-slider-offers.png"
                    alt="Número de ofertas"
                    style={{ maxWidth: "70%", height: "auto" }}
                    className="leading-[26px] mx-auto"
                  />
                </div>
              </div>
            </Slider>
            <img
              onClick={handleLeftArrowClick1}
              className="absolute transform -translate-y-1/2 cursor-pointer left-2 top-1/2"
              src="/images/carousel-info-arrow-left.png"
              alt="Arrow Left"
            />
            <img
              onClick={handleRightArrowClick1}
              className="absolute transform -translate-y-1/2 cursor-pointer right-2 top-1/2"
              src="/images/carousel-info-arrow-right.png"
              alt="Arrow Right"
            />
          </div>

          {/* Benefits */}
          <div className="rounded-[21px] bg-white mt-5 shadow-[0_3px_16px_0px_rgba(0,0,0,0.25)] pt-[34px]">
            <div className="text-center">
              <p className="text-[29px] font-bold leading-[33px] text-[#FF785F] mb-3">
                La Oportunidad de Compra
              </p>
              <p className="text-[21px] font-semibold leading-[29px] mb-3 text-[#00005E]">
                Nuestro Enfoque Único
              </p>
              <p className="text-[16px] leading-[21px] mb-10 text-[#5D6172] px-6">
                Nuestra <span className="font-semibold">propuesta de valor</span>{" "}
                se centra en subastar la{" "}
                <span className="font-semibold">"Oportunidad de Compra"</span> en
                lugar del vehículo.
              </p>
              <p className="text-[29px] font-semibold leading-[33px] text-[#00005E]">
                Beneficios
              </p>
            </div>

            <div className="w-full mt-6">            
              <div className="max-w-[94vw] lg:max-w-[98vw]">
                <Slider
                  {...settings}
                >
                  <div>
                    <img className="pointer-events-none" src="/images/benefits-1.png" alt="" />
                  </div>

                  <div>
                    <img className="pointer-events-none" src="/images/benefits-2.png" alt="" />
                  </div>

                  <div>
                    <img className="pointer-events-none" src="/images/benefits-3.png" alt="" />
                  </div>

                  <div>
                    <img className="pointer-events-none" src="/images/benefits-4.png" alt="" />
                  </div>

                  <div>
                    <img className="pointer-events-none" src="/images/benefits-5.png" alt="" />
                  </div>

                  <div>
                    <img className="pointer-events-none" src="/images/benefits-6.png" alt="" />
                  </div>

                </Slider>
              </div>
            </div>
          </div>

          {/* Principles */}
          <div className="rounded-[21px] bg-white mt-5 shadow-[0_3px_16px_0px_rgba(0,0,0,0.25)] pt-[34px]">
            <div className="text-center">
              <p className="text-[29px] font-bold leading-[33px] text-[#FF785F] mb-5 mx-3">
                <span className="text-[#00005E]">Nuestros</span> tres principios
                fundamentales
              </p>
            </div>
            <div className="relative">
              <img
                className="absolute left-7 top-6 max-w-[15px]"
                src="/images/principles-timeline.png"
                alt="Viñetas de principios"
              />
              <p className="text-[18px] leading-[29px] mb-7 text-[#00005E] px-16">
                <span className="font-bold">Experiencia</span> que conecta{" "}
                <span className="font-bold text-[#FF785F]">oportunidades</span>.
              </p>
              <p className="text-[18px] leading-[29px] mb-7 text-[#00005E] px-16">
                <span className="font-bold">Confianza</span> que genera{" "}
                <span className="font-bold text-[#FF785F]">confianza</span>.
              </p>
              <p className="text-[18px] leading-[29px] mb-7 text-[#00005E] px-16">
                <span className="font-bold">Innovación</span> que genera{" "}
                <span className="font-bold text-[#FF785F]">eficiencia</span>.
              </p>
            </div>
            <div>
              <img
                className="flex justify-center"
                src="/images/card-fundamentals-principles.png"
                alt="Principios Fundamentales Subastin"
              />
            </div>
          </div>

          {/* Info */}
          <div className="relative rounded-[21px] bg-[#00005E] mt-5 shadow-[0_3px_16px_0px_rgba(0,0,0,0.25)] py-8 px-6">
            <img
              className="absolute top-4 left-3"
              src="/images/top-left-corner.png"
              alt="Top left corner"
            />
            <p className="text-[21px] font-semibold leading-[37px] text-white text-center">
              Hoy en día, VMC Subastas es la principal plataforma de{" "}
              <span className="text-[#FBB44C]">
                compra-venta de activos B2C de Perú.
              </span>
            </p>
            <img
              className="absolute bottom-4 right-3"
              src="/images/bottom-right-corner.png"
              alt="Bottom right corner"
            />
          </div>

          {/* Solutions */}
          <div className="rounded-[21px] bg-white mt-5 pb-8 shadow-[0_3px_16px_0px_rgba(0,0,0,0.25)]">
            <div className="mx-3 text-center mt-7">
              <p className="text-[34px] font-bold leading-[32px] text-[#00005E] mx-5">
                Nuestra <span className="text-[#FF785F]">Solución</span>{" "}
              </p>
              <span className="text-[21px] text-[#00005E] font-[500]">para:</span>
            </div>

            <div className="mx-3 mt-12">
              <a
                href="https://zm43lmb8jl6.typeform.com/to/iuhSdxen"
                target="blank"
              >
                <img
                  className="w-full solutions-shadow"
                  src="/images/solutions-concessionaires.png"
                  alt="Imagen concesionarios"
                />
              </a>
            </div>

            <div className="mx-3 mt-5">
              <a
                href="https://zm43lmb8jl6.typeform.com/to/GYx9svnY"
                target="blank"
              >
                <img
                  className="w-full solutions-shadow"
                  src="/images/solutions-industrial.png"
                  alt="Imagen industriales"
                />
              </a>
            </div>

            <div className="mx-3 mt-5">
              <a 
                href="https://zm43lmb8jl6.typeform.com/to/kolfkBqR"
                target="blank"
              >
                <img
                  className="w-full solutions-shadow"
                  src="/images/solutions-individuals.png"
                  alt="Imagen particulares"
                />
              </a>
            </div>
          </div>

          {/* Statistics */}
          <div className="rounded-[21px] bg-white mt-5 px-3 pt-7 pb-9 shadow-[0_3px_16px_0px_rgba(0,0,0,0.25)]">
            <p className="text-[29px] font-bold leading-[34px] text-[#00005E] mx-5 text-center mb-3">
              Conecta con una amplia red de{" "}
              <span className="text-[#FF785F]">usuarios serios</span>
            </p>
            <p className="text-[#5D6172] text-[16px] leading-[21px] mb-4">
              VMC Subastas <span className="font-semibold">abre las puertas</span>{" "}
              de un mundo de oportunidades.
            </p>
            <p className="text-[#5D6172] text-[16px] leading-[21px] mb-6">
              <span className="font-semibold">Empoderamos a los vendedores</span>{" "}
              a convertir{" "}
              <span className="font-semibold">
                desafios en emocionantes oportunidades
              </span>
              .
            </p>
            <img
              className="w-full mb-7"
              src="/images/buyers-graphic.png"
              alt="Gráfica compradores"
            />
            <p className="text-[#00005E] text-[16px] leading-[21px]">
              Como tu{" "}
              <span className="font-semibold text-[#FF785F]">
                socio estratégico
              </span>
              , siempre pensamos en{" "}
              <span className="font-semibold">
                bajar tus costos operativos y maximizar tu retorno
              </span>
              .
            </p>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  );
}
